import AddTask from './AddTask/AddTask';
import Tasks from './Tasks/Tasks';

const Home = () => {
  return (
    <>
      <AddTask />
      <Tasks />
    </>
  );
};

export default Home;
